import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import styled, { createGlobalStyle, css } from "styled-components";
import { lighten } from "polished";
import { point, featureCollection } from "@turf/helpers";
import MDSpinner from "react-md-spinner";
import Helmet from "react-helmet-async";
import qs from "query-string";

import mapPin from "@common/website/assets/images/map_pin@1x.png";
import mapPinGold from "@common/website/assets/images/map_pin_gold@1x.png";
import phoneIcon from "@common/website/assets/svg/phone_icon.svg";
import emailIcon from "@common/website/assets/svg/email_icon.svg";

import { OFFICES_IN_VICINITY_QUERY } from "@common/website/gql/queries/offices-in-vicinity.gql";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

import fetch from "isomorphic-unfetch";

const PRODUCTION = process.env.NODE_ENV === "production";
const STAGING = process.env.RELEASE === "staging";

let client = null;
let cache = new InMemoryCache();
if (typeof window !== "undefined") {
	cache.restore(window.__APOLLO_STATE__);
	client = new ApolloClient({
		cache: cache,
		link: new BatchHttpLink({
			uri: PRODUCTION
				? STAGING
					? "https://staging-pmgraph.reeltime.no/graphql"
					: "https://pmgraph.reeltime.no/graphql"
				: "https://pmgraph.reeltime.no/graphql",
			credentials: "same-origin",
			fetch
		})
	});
} else {
	client = new ApolloClient({
		cache: cache,
		link: new BatchHttpLink({
			uri: PRODUCTION
				? STAGING
					? "https://staging-pmgraph.reeltime.no/graphql"
					: "https://pmgraph.reeltime.no/graphql"
				: "https://pmgraph.reeltime.no/graphql",
			credentials: "same-origin",
			fetch
		})
	});
}

const OfficesInVicinity = ({ history, location: historyLocation }) => {
	const [metaKeyActive, setMetaKeyActive] = useState(false);
	const [initial, setInitial] = useState(true);
	const [loading, setLoading] = useState(true);
	const listContainer = useRef(null);
	const [offices, setOffices] = useState(null);
	const [foundByCoordinates, setFoundByCoordinates] = useState(true);
	const [resultsFromQuery, setResultsFromQuery] = useState(true);
	const [activeOffice, setActiveOffice] = useState(null);
	const [location, setLocation] = useState(null);
	const [locationChecked, setLocationChecked] = useState(false);
	const [locationFailed, setLocationFailed] = useState(false);
	const mapContainer = useRef(null);
	const [mapbox, setMapbox] = useState(null);
	const [map, setMap] = useState(null);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [mapInitialized, setMapInitialized] = useState(false);
	const [markers, setMarkers] = useState([]);
	const [zip, setZip] = useState("");
	const [zipIsSet, setZipIsSet] = useState(false);

	const searchVariables = ({ zip, lat, lon }) => {
		let officeVariables = {};
		if (zip) {
			officeVariables = {
				zip: {
					value: zip,
					distance: 5000,
					unit: "km"
				}
			};
		} else {
			officeVariables = {
				geolocation: {
					lat: lat,
					lon: lon,
					distance: 5000,
					unit: "km"
				}
			};
		}
		return {
			input: {
				query: "",
				filter: {
					office: officeVariables
				},
				searchIn: [{ type: "office", pagination: { page: 1, limit: 1000 } }]
			}
		};
	};

	const search = () => {
		client
			.query({
				query: OFFICES_IN_VICINITY_QUERY,
				fetchPolicy: "network-only",
				variables: searchVariables({
					zip: zip,
					lat: location?.lat,
					lon: location?.lon
				})
			})
			.then(({ data }) => {
				if (
					data &&
					data.search &&
					data.search.result &&
					data.search.result.offices &&
					data.search.result.offices.list.length
				) {
					setOffices(data.search.result.offices.list);
					setActiveOffice(data.search.result.offices.list[0]);
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		const { zip, location } = qs.parse(historyLocation.search);
		if (zip) {
			setZip(zip);
			setZipIsSet(true);
		}

		if (location && location.length) {
			setLocation({
				lat: location[0],
				lon: location[1]
			});
		}
		if ("geolocation" in navigator && !location) {
			navigator.geolocation.getCurrentPosition(
				({ coords }) => {
					if (coords) {
						setLocation({
							lat: String(coords.latitude),
							lon: String(coords.longitude)
						});
						setLocationChecked(true);
					}
				},
				err => {
					setLoading(false);
					setLocationFailed(true);
					setLocationChecked(true);
				},
				{
					enableHighAccuracy: true,
					timeout: 10000
				}
			);
		} else {
			setLoading(false);
			setLocationFailed(true);
			setLocationChecked(true);
		}
	}, []);

	useEffect(() => {
		if (
			locationChecked &&
			!mapbox &&
			typeof window !== undefined &&
			window.matchMedia("screen and (min-width: 768px)").matches
		) {
			import(/* webpackChunkName: "mapbox-gl" */ "mapbox-gl").then(({ default: mapboxGl }) => {
				setMapbox(mapboxGl);
			});
		}
	}, [locationChecked]);

	useEffect(() => {
		setLoading(true);
		search();
	}, [locationChecked, mapLoaded]);

	useEffect(() => {
		if (offices && offices.length && mapLoaded && locationChecked && !locationFailed) {
			setActiveOffice(offices[0]);
		}
	}, [offices, mapLoaded, locationChecked, locationFailed]);

	useEffect(() => {
		if (mapbox && mapContainer && mapContainer.current && !map) {
			mapbox.accessToken =
				"pk.eyJ1IjoibWhhYWdlbnM4NyIsImEiOiJjamZ3b2NlMDIxbnB4MndxbjBnZG55czhqIn0.vLnOFOXpgqA380gBjES99w";
			console.log(location);
			setMap(
				new mapbox.Map({
					container: "map",
					style: "mapbox://styles/mapbox/streets-v9",
					center: location ? [location.lon, location.lat] : [12.307778, 63.990556],
					zoom: location ? 10 : 5,
					pitch: 60,
					bearing: -60
				})
			);
		}
	}, [mapbox, location, mapContainer]);

	useEffect(() => {
		if (map && !mapLoaded) {
			map.on("load", () => {
				setMapLoaded(true);
			});
		}
	}, [map]);

	function isJSON(data) {
		try {
			return JSON.parse(data);
		} catch (err) {
			return data;
		}
	}

	useEffect(() => {
		if (activeOffice && activeOffice.coordinates && mapLoaded) {
			let coordinates = isJSON(activeOffice.coordinates);
			const o = {
				lat: coordinates.lat,
				lon: coordinates.lon
			};
			setTimeout(() => {
				map.flyTo({ center: [o.lon, o.lat], zoom: 15 });
			}, 1000);
		}
	}, [activeOffice, mapLoaded]);

	useEffect(() => {
		if (mapLoaded) {
			map.loadImage(mapPin, (err, imageNormal) => {
				if (!err) {
					map.addImage("pm_marker", imageNormal);
				} else {
					console.log(err);
				}
				map.loadImage(mapPinGold, (err, imageGold) => {
					if (!err) {
						map.addImage("pm_marker_gold", imageGold);
					} else {
						console.log(err);
					}

					map.addSource("properties", {
						type: "geojson",
						data: null,
						cluster: true,
						clusterMaxZoom: 14,
						clusterRadius: 50
					});

					map.addLayer({
						id: "clusters",
						type: "circle",
						source: "properties",
						filter: ["has", "point_count"],
						paint: {
							"circle-color": "#e8c893",
							"circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
						}
					});

					map.addLayer({
						id: "cluster-count",
						type: "symbol",
						source: "properties",
						filter: ["has", "point_count"],
						layout: {
							"text-field": "{point_count_abbreviated}",
							"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
							"text-size": 12
						},
						paint: {
							"text-color": "#000000"
						}
					});

					if (!err) {
						map.addLayer({
							id: "unclustered-point",
							type: "symbol",
							source: "properties",
							filter: ["all", ["!has", "point_count"], ["!=", "isZipMatch", true]],
							layout: {
								"icon-image": "pm_marker",
								"icon-size": 0.3,
								"icon-allow-overlap": true
							}
						});
						map.addLayer({
							id: "unclustered-point-active",
							type: "symbol",
							source: "properties",
							filter: ["all", ["!has", "point_count"], ["==", "isZipMatch", true]],
							layout: {
								"icon-image": "pm_marker",
								"icon-size": 0.475,
								"icon-allow-overlap": true
							}
						});
					} else {
						map.addLayer({
							id: "unclustered-point",
							type: "circle",
							source: "properties",
							filter: ["!", ["has", "point_count"]],
							paint: {
								"circle-color": "#e8c893",
								"circle-radius": 4,
								"circle-stroke-width": 1,
								"circle-stroke-color": "#000"
							}
						});
					}

					const layers = map.getStyle().layers;

					let labelLayerId;
					for (var i = 0; i < layers.length; i++) {
						if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
							labelLayerId = layers[i].id;
							break;
						}
					}

					map.on("click", "clusters", e => {
						const features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
						const clusterId = features[0].properties.cluster_id;
						map.getSource("properties").getClusterExpansionZoom(clusterId, (err, zoom) => {
							if (err) return;

							map.easeTo({
								center: features[0].geometry.coordinates,
								zoom: zoom
							});
						});
					});

					const handleClick = (e, layer) => {
						const [feature] = map.queryRenderedFeatures(e.point, { layers: [layer] });
						if (feature && feature.properties) {
							map.flyTo({ center: feature.geometry.coordinates, zoom: 15 });
							const property = feature.properties;
							if (property && property.id) {
								setActiveOffice(property);
							}

							const activeElement = document.querySelector(".office-card-active");

							if (activeElement) {
								const top = activeElement.offsetTop;

								listContainer.current.scrollTo({
									top: top - 162,
									left: 0,
									behavior: "smooth"
								});
							}
						}
					};

					map.on("click", "unclustered-point", e => handleClick(e, "unclustered-point"));
					map.on("click", "unclustered-point-active", e => handleClick(e, "unclustered-point-active"));

					map.on("mouseenter", "clusters", () => {
						map.getCanvas().style.cursor = "pointer";
					});
					map.on("mouseleave", "clusters", () => {
						map.getCanvas().style.cursor = "";
					});
					map.on("mouseenter", "unclustered-point", () => {
						map.getCanvas().style.cursor = "pointer";
					});
					map.on("mouseleave", "unclustered-point", () => {
						map.getCanvas().style.cursor = "";
					});
					map.on("mouseenter", "unclustered-point-active", () => {
						map.getCanvas().style.cursor = "pointer";
					});
					map.on("mouseleave", "unclustered-point-active", () => {
						map.getCanvas().style.cursor = "";
					});
					setMapInitialized(true);
				});
			});
		}
	}, [mapLoaded]);

	useEffect(() => {
		if (mapInitialized && offices) {
			const points = [];
			for (let o of offices) {
				if (o.coordinates && o.coordinates.lat && o.coordinates.lon) {
					points.push(
						point([o.coordinates.lon, o.coordinates.lat], {
							...o
						})
					);
				}
			}
			setMarkers(points);
		}
	}, [mapInitialized, offices]);

	useEffect(() => {
		if (mapInitialized && markers.length) {
			const pointsFeatureCollection = featureCollection(markers);
			map.getSource("properties").setData(pointsFeatureCollection);

			const bounds = new mapbox.LngLatBounds();

			pointsFeatureCollection.features.forEach(f => {
				bounds.extend(f.geometry.coordinates);
			});

			try {
				if (bounds && markers.length) {
					map.fitBounds(bounds);
				}
			} catch (e) {
				console.log(e);
			}
		}
	}, [mapInitialized, markers]);

	return (
		<>
			<Helmet>
				<title>PrivatMegleren - Kontorer i nærheten</title>
			</Helmet>
			<FooterOverride />
			<Container>
				{locationChecked ? (
					<>
						<Left ref={mapContainer} id="map" />
						<Right>
							<RightHeader>
								<Title>Kontorer i nærheten</Title>
								<Form
									onSubmit={e => {
										setLoading(true);
										e.preventDefault();
										if (initial) {
											setInitial(false);
										}
										search();
									}}
								>
									<Input
										type="number"
										min="0"
										inputMode="numeric"
										pattern="[0-9]*"
										placeholder="Søk med postnummer"
										value={zip}
										onKeyPress={e => {
											if (zip.length >= 4 && !metaKeyActive && e.key !== "Enter" && e.key !== "Backspace") {
												if (metaKeyActive) {
													setMetaKeyActive(false);
												}
												e.preventDefault();
											} else if (metaKeyActive && zip.length < 4) {
												setMetaKeyActive(false);
											}
										}}
										onKeyDown={e => {
											if ((e.metaKey || e.ctrlKey) && e.key === "a") {
												setMetaKeyActive(true);
											}
										}}
										onChange={e => {
											if (zip.match(/^[0-9]*$/g)) {
												setZip(e.target.value);
											}
										}}
										onFocus={e => {
											const val = e.target.value;
											e.target.value = "";
											e.target.value = val;
										}}
									/>
									<Submit type="submit">Søk</Submit>
								</Form>
							</RightHeader>
							<RightContent ref={listContainer}>
								{!loading && offices && offices.length && !resultsFromQuery && (location || initial === false) ? (
									<LocationMessage>
										Vi finner ikke et kontor basert på ditt søk. <br />
										Våre kontorer:
									</LocationMessage>
								) : null}
								<OfficeCards>
									{offices && offices.length
										? offices.map((o, i) => (
											<OfficeCard
												key={o.id}
												isOwner={activeOffice && activeOffice.zip === o.zip}
												className={`office-card-${activeOffice && activeOffice.id == o.id ? "active" : "inactive"}`}
												isActive={activeOffice && activeOffice.id == o.id}
											>
												<OfficeCardLeft>
													<OfficeLink href={`https://privatmegleren.no/${o.urltag}`}>
														<County>{o.municipality}</County>
														<Name>PrivatMegleren {o.name}</Name>
													</OfficeLink>
												</OfficeCardLeft>
												{o.distance ? (
													<OfficeCardRight isOwner={o.isZipMatch}>
														{!o.distance.value ? "" : `${o.distance.value}km unna`}
													</OfficeCardRight>
												) : null}
												<ContactInfo>
													<ContactInfoItem href={`mailto:${o.email}`}>
														<ContactInfoIcon dangerouslySetInnerHTML={{ __html: emailIcon }} /> {o.email}
													</ContactInfoItem>
													<ContactInfoItem href={`tel:${o.phone}`}>
														<ContactInfoIcon last dangerouslySetInnerHTML={{ __html: phoneIcon }} /> {o.phone}
													</ContactInfoItem>
												</ContactInfo>
												<Buttons isOwner={activeOffice && activeOffice.zip == o.zip}>
													<Button
														as="a"
														href={`https://privatmegleren.no/${o.urltag}`}
														isActive={activeOffice && activeOffice.id == o.id}
														isOwner={activeOffice && activeOffice.zip == o.zip}
													>
														Kontorets hjemmeside
													</Button>
													<Button
														as="a"
														href={`https://privatmegleren.no/${o.urltag}/verdivurdering`}
														isActive={activeOffice && activeOffice.id == o.id}
														isOwner={activeOffice && activeOffice.zip == o.zip}
													>
														Verdivurdering
													</Button>
													<Button
														desktopOnly
														isActive={activeOffice && activeOffice.id == o.id}
														isOwner={activeOffice && activeOffice.zip == o.zip}
														onClick={() => {
															map.flyTo({ center: [o.coordinates.lon, o.coordinates.lat], zoom: 15 });
															setActiveOffice(o);
														}}
													>
														{activeOffice && activeOffice.id === o.id ? "Vises" : "Se i kart"}
													</Button>
												</Buttons>
											</OfficeCard>
										))
										: null}
								</OfficeCards>
							</RightContent>
						</Right>
					</>
				) : (
					<SpinnerWrap>
						<SpinnerContainer>
							<MDSpinner singleColor="#e8c893" size={48} />
						</SpinnerContainer>
					</SpinnerWrap>
				)}
			</Container>
		</>
	);
};

const FooterOverride = createGlobalStyle`
	.footer {
		display: none;
	}
`;

const LocationMessage = styled.span`
	display: block;
	min-width: 100%;
	margin: 0 auto 18px auto;
	text-align: center;
	opacity: 0.5;
`;

const ShareLocationButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: min-content;
	white-space: nowrap;
	margin: 0 auto;
	padding: 6px 18px;
	cursor: pointer;
	background: ${({ theme }) => theme.colors.gold.primary};
	color: #000;
`;

const SpinnerWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: calc(100vh - 60px);
	background: #000;
`;

const SpinnerContainer = styled.div`
	display: flex;
	width: 48px;
	height: 48px;
	align-items: center;
	justify-content: center;
	margin-right: 9px;
`;

const Container = styled.div`
	display: flex;
	flex-flow: row nowrap;
	position: fixed;
	top: 72px;
	left: 0;
	width: 100%;
	height: calc(100% - 72px);
	background: #000;
	z-index: 1;
`;

const Left = styled.div`
	display: none;
	background: #ddd;
	width: 65%;
	min-height: 100%;

	@media screen and (min-width: 768px) {
		display: flex;
		width: 65%;
	}
`;

const Right = styled(Left)`
	display: flex;
	flex-flow: column;
	width: 100%;
	background: #181819;

	@media screen and (min-width: 768px) {
		width: 35%;
	}
`;

const RightHeader = styled.header`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	height: 150px;
	border-bottom: 1px solid #333;
	padding: 0 24px;
`;

const RightContent = styled.div`
	display: flex;
	flex-flow: column;
	flex: 1;
	padding: 24px 24px 24px 24px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
`;

const Title = styled.h1`
	font-size: 30px;
	margin: 0 0 18px 0;
`;

const OfficeCards = styled.ul`
	display: block;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
`;

const OfficeCard = styled.li`
	position: relative;
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	padding: 24px;
	margin-bottom: 12px;
	background: ${({ isActive, isOwner }) => (isOwner ? "#1A1A1A" : isActive ? lighten(0.035, "#2a2a2a") : "#2a2a2a")};
	color: ${({ isOwner }) => (isOwner ? "#fff" : "#fff")};
	border: ${({ isOwner }) => (isOwner ? "2px solid #E4C490" : "none")};

	&& {
		font-size: ${({ isOwner }) => (isOwner ? "15px" : "13px")};
	}

	&:before {
		content: "";
		display: ${({ isActive, isOwner }) => (isActive && !isOwner ? "block" : "none")};
		position: absolute;
		top: 0;
		left: 0;
		width: 2px;
		height: 100%;
		background: ${({ theme }) => theme.colors.gold.primary};
	}

	h4 {
		font-family: ${({ isOwner }) => (isOwner ? "Domaine Display" : "Proxima Nova")};
		font-size: ${({ isOwner }) => (isOwner ? "24px" : "16px")};
		margin-bottom: ${({ isOwner }) => (isOwner ? "6px" : "0")};
	}

	h5 {
		font-size: ${({ isOwner }) => (isOwner ? "15px" : "13px")};
	}

	h4,
	small {
		color: #e4c490;
	}

	a {
		color: #fff;
	}

	svg {
		path {
			fill: #e4c490;
		}
	}
`;

const OfficeLink = styled.a`
	text-decoration: none;
`;

const OfficeCardLeft = styled.div``;

const County = styled.h4`
	font-weight: 600;
	margin: 0 0 3px 0;
`;

const Name = styled.h5`
    font-family: 'Geograph-Regular',sans-serif;
	margin: 0;
	font-weight: 400;
`;

const Buttons = styled.div`
	display: flex;
	flex-flow: column;
	margin-top: 18px;
	width: 100%;

	a,
	button {
		background: ${({ isActive, theme, isOwner }) =>
		isOwner ? "#E7CFA7" : isActive ? "#E7CFA7" : lighten(0.085, "#2a2a2a")};
		color: ${({ isOwner }) => (isOwner ? "#000" : "#fff")};
		padding: ${({ isOwner }) => (isOwner ? "6px 12px" : "3px 9px 2px 9px")};

		&:hover {
			background: ${({ isOwner }) => (isOwner ? "#000" : lighten(0.15, "#2a2a2a"))};
			color: ${({ isOwner }) => (isOwner ? "#E4C490" : "#fff")};
		}
	}

	@media screen and (min-width: 768px) {
		flex-flow: row wrap;
		a,
		button {
			margin-bottom: 10px;
		}
	}
`;

const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	margin-right: 6px;
	margin-bottom: 0;
	border: 1px solid #555;
	border: 0;
	text-decoration: none;
	cursor: pointer;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 6px 12px;
		margin-right: 0;
		margin-bottom: 5px;
	}

	${({ desktopOnly }) =>
		desktopOnly
			? css`
					@media screen and (max-width: 768px) {
						display: none;
					}
			  `
			: ""};
`;

const OfficeCardRight = styled(OfficeCardLeft)`
	margin-left: auto;
	padding-top: 3px;
	opacity: ${({ isOwner }) => (isOwner ? "1" : "0.5")};
	color: ${({ isOwner }) => (isOwner ? "#E4C490" : "#fff")};

	@media screen and (max-width: 768px) {
		margin-left: auto;
	}
	@media screen and (min-width: 769px) and (max-width: 1100px) {
		margin-left: 0;
	}
`;

const ContactInfo = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	margin-top: 18px;

	@media screen and (min-width: 768px) {
		flex-flow: row wrap;
	}
`;

const ContactInfoItem = styled.a`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-right: 12px;
	color: #fff;
	text-decoration: none;
	word-break: break-all;
`;

const ContactInfoIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	margin-right: ${({ last }) => (last ? "3px" : "5px")};
	svg {
		width: ${({ last }) => (last ? "13px" : "16px")};
		width: ${({ last }) => (last ? "13px" : "16px")};
	}
`;

const Form = styled.form`
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	height: 42px;
`;

const Input = styled.input`
	flex: 1;
	padding: 0 12px;
	background: #2a2a2a;
	color: #fff;
	border: 0;
	border-radius: 0;
	-webkit-border-radius: 0px;

	&::placeholder {
		color: #fff;
	}

	&:focus {
		outline: 0;
	}

	-moz-appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		appearance: none;
	}
`;

const Submit = styled.button`
	display: flex;
	align-items: center;
	padding: 0 18px;
	background: #e8c893;
	margin-left: 5px;
	color: #000;
	border: 0;
	cursor: pointer;
`;

OfficesInVicinity.propTypes = {
	client: PropTypes.any,
	history: PropTypes.any,
	location: PropTypes.any
};

export default withApollo(withRouter(OfficesInVicinity));
